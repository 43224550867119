.board_ {
  width: 600px!important;
  height: 660px!important;
  margin-left: 5%;
  margin-top: 3%;
  border: 3px solid white;
}

body {
  background-color: #111111;
}

.name_space {
  color: white;
  margin: 5px;
  font-family: 'cascadia code';
}

.game_info {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10%;
  margin-left: -22.5%;
  border: white 3px solid;
  height: fit-content;
  width: 350px;
}

.game_info_title {
  color: aqua;
  font-size: large;
  font-family: 'Cascadia Code';
  text-decoration: underline 3px white;
}

.your_colour {
  color: aqua;
  font-size: large;
  font-family: 'Cascadia Code';
  padding-left: 5%;
}

.game_status {
  color: aqua;
  font-size: xx-large;
  font-family: 'Cascadia Code';
  padding-top: 6%;
  margin-left: 2.5%;
  height: fit-content;
}

.all_div {
  display: flex;
}

html {
  background-color: #090909!important;
  overflow: auto!important;
}

.button {
  border-radius: 12px;
  font-size: medium;
  font-family: 'Cascadia code';
  width: 80%;
  margin-left: 10%;
  height: 5%;
  background-color: aqua;
  color: black;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: #00b3b3;
}

.button:active {
  background-color: #008080; 
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}

.chat-box {
  margin-top: 22rem;
  margin-left: -37%;
  width: 37.5%;
  height: 21.5rem;
  border-radius: 12px;
  background-color: #0c0c0c;
  border: 2px solid #00b3b3;
  overflow: auto;
}

.msg {
  padding: 5px;
  font-family: 'Cascadia Code';
  border-radius: 6px;
  margin: 5px;
  background-color: #00b3b3;
  color: black;
}

.msg_input {
  padding: 8px;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  height: fit-content;
  overflow: auto;
  position: absolute;
  left: 48%;
  width: 29%;
  top: 89%;
  background-color: #272727;
  color: white;
}

.msg_input::placeholder {
  color: #a6a6a6;
}

.msg_input:focus {
  outline: none;
  box-shadow: 0 0 2px 1px aqua;
}
.send {
  position: absolute;
  padding: 8px;
  left: 78.3%;
  top: 88.8%;
  width: 5.8%;
  height: fit-content;
  border-radius: 6px;
  cursor: pointer;
  background-color: #00b3b3;
  color: #000;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
.send:hover {
  background-color: #008080;
}
.send:active {
  background-color: #006666;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}
.my_msg{
  color: black;
  float: right;
  clear: both;
}
.opponent_msg{
  clear: both;
  float: left;
}
@media screen and (max-width: 1270px) {
  .all_div{
    flex-direction: column;
  }
  .game_info{
    margin-left: 8%;
    margin-top: 1%;
  }
   .game_status{
    padding-top: 00.5%;
    margin-left: 5%;
  }
  .chat-box{
    width: 98%;
    margin-left: 1%;
    margin-top: 1rem;
  }
  .chat-box input{
    margin-top: 48.5%;
  }
  .msg_input{
    left: 4%;
    width: 81%;
    top: 167%;
  }
  .send{
    left: 88%;
    top: 167%;
    width: fit-content;
    height: fit-content;
  }
}
@media screen and (min-height: 750px) {
  .send{
    top: 150%;
  }
  .msg_input{
    top: 150%;
  }
}