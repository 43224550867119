#home_background{
  position: absolute;
  width: 99%;
  height: 70%;
  background-color: black;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
}
html{
  background-color: #000000!important;
}
#enter_name_text {
  font-family: 'Cascadia code';
  font-size: x-large;
  color: #fff;
  margin-left: 39%;
}
#name_input {
  border-radius: 20px;
  margin-left: 5%;
  margin-top: 1%;
  width: 90%;
  height: 3%;
}
#submit_button {
  border-radius: 12px;
  font-size: x-large;
  font-family: 'Cascadia code';
  margin-left: 38%;
  margin-top: 2%;
  width: 20%;
  height: 7%;
  background-color: aqua;
  color: black;
  border: none;
  cursor: pointer;
}
#submit_button:hover {
  background-color: #00b3b3;
}
#submit_button:active {
  background-color: #008080; 
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
}
.link_div{
  justify-content: space-between;
  background-color: aqua;
  /* background-color: #595959; */
  border-radius: 12px;
  position: absolute;
  margin-left: 7%;
  display: flex;
  bottom: 10%;
  width: 85%;
  height: 7%;
  opacity: 0;
  z-index: 2;
}
.link_text{
  color: black;
  font-family: 'Cascadia code';
  font-size: large;
  text-decoration: black 3px underline ;
  padding-top: 8px;
  padding-left: 5px;
  margin-top: 1%;
}
.copy_image{
  width: 45px;
  height: 45px;
  border-radius: 8px;
  margin-top: 0.3%;
  padding-right: 5px;
}
@media screen and (max-width: 1000px) {
  #enter_name_text{
    margin-left: 30%;
    font-size: 20px;
  }
  #name_input{
    margin-top: 3%;
  }
}
@media screen and (max-width: 650px) {
  .link_div{
    height: 10%;
  }
}
@media screen and (max-width: 400px) {
  #enter_name_text{
    margin-left: 22%;
    font-size: 18px;
  }
  #submit_button{
    font-size: medium;
  }
}